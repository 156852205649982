<template>

  <div>
    <div class="container">

      <div class="row page-part">
        <div class="col-md-12 page-title">
          关于
        </div>
        <div class="col-md-12 page-body">
          <p class="page-body">
            创意源是一个持续发展的创新型知识价值平台。<br/>
            创意源的起点很简单，但是有远大的梦想目标。<br/>
            未来不确定是否会实现设立的远大目标，但是会享受持续实现目标的过程！<br/>
          </p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

</script>
